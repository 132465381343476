<template>
  <div>
    <q-table
      :columns="columns"
      :data="value"
      :rows-per-page-options="[0]"
      hide-pagination
      separator="cell"
    >
      <template #top>
        <q-space />
        <q-btn color="primary" label="Add agent" @click="addRow()" />
      </template>
      <template #body-cell-agent-id="props">
        <q-td :props="props">
          <d-user-select
            label="Agent"
            dense
            map-options
            :options="agentsDictionary"
            :value="composeAgentData(props.row)"
            :multiple="false"
            :outlined="false"
            :rules="[(val) => !!val || 'Agent must be selected']"
            @input="updateRow($event, props, 'agentId')"
            hint="Required field *"
          />
        </q-td>
      </template>
      <template #body-cell-percent="props">
        <q-td :props="props">
          <q-input
            :error="isPercentageSumError"
            label="Percent (%)"
            type="number"
            dense
            hint="Required field *"
            :rules="[
              (val) => !!val || 'Value must be entered',
              (val) => val > 0 || 'Value must be greater than zero',
            ]"
            :value="props.row.percent"
            @input="updateRow(+$event, props, 'percent')"
          />
        </q-td>
      </template>
      <template #body-cell-actions="props">
        <q-td :props="props">
          <q-btn flat fab-mini icon="mdi-delete" @click="onRemove(props)" />
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<script>
import DUserSelect from '@/features/user-select';
import { percentageColumns } from '@/pages/payout-assignment-rules/edit/config';
import { usersController } from '@/shared/api';
import { authUtils, notify } from '@/shared/utils';

export default {
  components: {
    DUserSelect,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    isPercentageSumError: {
      type: Boolean,
      default: () => false,
    },
    payoutMethodTypeId: {
      type: Number,
      default: () => null,
    },
  },
  data: () => ({
    loading: false,
    agentsDictionary: [],
    columns: percentageColumns,
  }),
  watch: {
    async payoutMethodTypeId(val) {
      if (val === null) return;
      try {
        const { data } = await usersController(
          authUtils.getRoleByHierarchy()
        ).agentsForPayoutMethod(val);

        this.agentsDictionary = data;
      } catch (e) {
        notify.error('Error loading directory');
      }
    },
  },
  async mounted() {
    try {
      this.loading = true;
      const { data } = await usersController(
        authUtils.getRoleByHierarchy()
      ).usersForFilter();

      this.agentsDictionary = data;
    } catch (e) {
      notify.error('Error loading directory');
    } finally {
      this.loading = false;
    }
  },
  methods: {
    composeAgentData({ agentId, agentName }) {
      if (!agentName) {
        return agentId;
      }
      return {
        id: agentId,
        username: agentName,
      };
    },
    onRemove({ rowIndex }) {
      this.$emit(
        'input',
        this.value.filter((_, idx) => idx !== rowIndex)
      );
    },
    addRow() {
      this.$emit('input', [{ agentId: null, percent: null }, ...this.value]);
    },
    updateRow(value, { rowIndex }, key) {
      const buildObj = () => {
        if (key === 'agentId') {
          return {
            agentName: value?.username,
            agentId: value?.id,
          };
        }
        if (key === 'percent') {
          return { percent: value };
        }
      };

      this.$emit(
        'input',
        this.value.map((el, idx) =>
          idx === rowIndex ? { ...el, ...buildObj() } : el
        )
      );
    },
  },
};
</script>
